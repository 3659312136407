<template>
  <div class="wrapper">
    <div class="tender-display pt-2">
      <tender-card
        v-for="tender in tenders"
        :key="tender.tenderGuid"
        :tender="tender"
        @show-detail="showDetail">
      </tender-card>
      <div
        v-for="wrapper in emptyWrappers"
        :key="wrapper.id"
        class="card-wrapper">
      </div>
    </div>

    <my-dialog ref="formDialog"
      title="标段详情"
      size="xl"
      :hide-ok="true"
      cancel-text="关闭"
      >
      <tender-form
        ref="tenderForm"
        :tenderModel="tenderModel">
      </tender-form>
    </my-dialog>
  </div>
</template>

<script>
import tenderService from '@/services/tenderService'
import MyDialog from '@/components/MyDialog/MyDialog'
import TenderForm from '@/views/TenderManage/TenderForm'
import TenderModel from '@/model/TenderModel'
import utility from '@/common/utility'
import auth from '@/common/auth'
import TenderCard from '@/views/TenderDisplay/TenderCard'

export default {
  name: 'TenderDisplay',
  components: {
    MyDialog,
    TenderForm,
    TenderCard
  },
  data () {
    return {
      tenderModel: new TenderModel(),
      tenders: []
    }
  },
  computed: {
    emptyWrappers () {
      let len = 5 - this.tenders.length
      if (len > 0) {
        return Array(len).fill(0).map(item => {
          return {
            id: utility.getUuid()
          }
        })
      }
      return []
    }
  },
  methods: {
    showDetail (tender) {
      this.tenderModel = new TenderModel(tender)
      this.$refs.formDialog.open()
    },
    _getTenders () {
      tenderService.listByUser(auth.getUserInfo().userGuid)
        .then(res => {
          if (res.data.code === 1) {
            this.tenders = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          window.concole.log(err.request.response)
        })
    }
  },
  created () {
    this._getTenders()
  }
}
</script>

<style scoped lang="scss">
.tender-display {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 10px 10px;
}
</style>
