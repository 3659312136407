<template>
  <div class="tender-card">
    <el-card
      shadow="hover"
      class="box-card"
      :body-style="{ padding: '0' }">
      <div class="my-card-header pt-2 pl-3 text-muted">
        <span>{{ tender.projectName }} - {{ tender.engineeringType }}标段</span>
      </div>
      <div class="my-card-content">
        <div class="content-detail">
          <h6 class="mb-1">
            <strong>{{ tender.tenderName }}标段</strong>
          </h6>
          <table>
            <tr>
              <td style="width: 65px; height: 30px;">施工单位：</td>
              <td>
                {{ tender.constructionUnit }}
              </td>
            </tr>
            <tr>
              <td style="width: 65px; height: 30px;">监理单位：</td>
              <td>
                {{ tender.superviseUnit }}
              </td>
            </tr>
            <tr>
              <td style="width: 65px; height: 30px;">业主单位：</td>
              <td>
                {{ tender.ownerUnit }}
              </td>
            </tr>
            <tr>
              <td style="width: 65px; height: 30px;">中标金额：</td>
              <td>
                {{ tender.winningAmount }}&nbsp;元
              </td>
            </tr>
          </table>
        </div>
        <div class="image rounded-circle shadow" :class="imageClass"></div>
      </div>
      <div class="my-card-footer">
        <div class="detail text-muted rounded" @click="showDetail">
          <i class="el-icon-document"></i>&nbsp;&nbsp;详情
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'TenderCard',
  props: {
    tender: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    imageClass () {
      let _class = ''
      if (this.tender.engineeringType === '土建') {
        _class = 'road'
      } else if (this.tender.engineeringType === '房建') {
        _class = 'house'
      } else if (this.tender.engineeringType === '交安') {
        _class = 'jiaoan'
      } else if (this.tender.engineeringType === '机电') {
        _class = 'jidian'
      } else if (this.tender.engineeringType === '市政') {
        _class = 'shizheng'
      }
      return _class
    }
  },
  methods: {
    showDetail () {
      this.$emit('show-detail', this.tender)
    }
  }
}
</script>

<style scoped lang="scss">
.tender-card {
  min-width: 390px;
}
.my-card-header {
  height: 30px;
  font-size: 14px;
  background-color: rgb(248,249,250);
}
.my-card-content {
  position: relative;
  height: 170px;
  background-color: rgb(248,249,250);
}
.my-card-footer {
  height: 50px;
  line-height: 50px;
  background-color: rgb(240,241,242);
  text-align: right;
}
.detail {
  display: inline-block;
  width: 70px;
  text-align: center;
  /* background-color: rgb(0,123,255); */
  background-color: #ccc;
  height: 30px !important;
  line-height: 30px !important;
  margin-right: 15px;
  font-size: 10px;
  cursor: pointer;
  transition: background-color .3s;
}
.detail:hover {
  background-color: #bbb;
}
.content-detail {
  position: absolute;
  font-size: 12px;
  left: 16px;
  top: 10px;
  bottom: 10px;
  right: 100px;
  line-height: 1;
}
.content-detail p {
  padding: 0;
  margin: 1px;
  display: block;
  height: 15px;
  line-height: 15px;
}
.image {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 90px;
  height: 90px;
  background-size: cover;
  background-color: #ccc;
}
.image.road {
  background-image: url('~@/assets/images/road.jpg')
}
.image.house {
  background-image: url('~@/assets/images/house.jpg')
}
.image.jiaoan {
  background-image: url('~@/assets/images/jiaoan.jpg')
}
.image.jidian {
  background-image: url('~@/assets/images/jidian.jpg')
}
.image.shizheng {
  background-image: url('~@/assets/images/shizheng.jpg')
}
</style>
